<template>
  <b-card>
    <header-table title="الزوار" @create="onClickedAdd" />

    <div class="border pt-1 px-1 rounded-lg mb-1 shadow-sm">
      <b-row>
        <b-col cols="3">
          <form-input v-model="filter" placeholder=" ابحث هنا عن اسم المستفيد..." />
        </b-col>
        <b-col cols="3">
          <form-select
            label=""
            id="state_benef"
            placeholder="حالة الخدمة"
            :reduce="(el) => el.value"
            v-model="status"
            :options="allStatusVisitor"
          />
        </b-col>
      </b-row>
    </div>

    <data-table
      ref="estatesTable"
      ep="/visitors"
      :fields="fields"
      :filter="filter"
      :utils="{ status, service_id }"
    >
      <template #row-details="row">
        <b-collapse id="collapse-1" class="mt-2">
          <h5 class="text-primary">الملاحظات</h5>
          <p>
            {{ row.item.notes }}
          </p>
        </b-collapse>
      </template>

      <template #cell(actions)="row">
        <b-button
          variant="flat-info"
          title="عرض المزيد من المعلومات"
          size="sm"
          v-ripple.400="'rgba(40, 199, 111, 0.15)'"
          class=""
          v-b-tooltip.hover.v-info.top
          @click="row.toggleDetails"
          v-b-toggle.collapse-1
        >
          المزيد
          <feather-icon
            :icon="row.detailsShowing ? 'ChevronUpIcon' : 'ChevronDownIcon'"
          />
          <!-- ChevronUpIcon -->
        </b-button>
        <actions-table @delete="onDelete(row.item)" @update="onUpdateClicked(row.item)" />
      </template>
      <!-- // TODO add service status -->
      <template #cell(is_execute)="row">
        <b-badge
          style="width: 60px"
          :variant="row.item.is_execute ? 'light-primary' : 'light-danger'"
          >{{ row.item.is_execute ? "منفذ" : "غير منفذ" }}</b-badge
        >
      </template>

      <template #cell(executed_date)="row">
        <div v-if="row.item.executed_date">
          <div>
            {{ $moment(row.item.executed_date).locale("en").format("YYYY-MM-DD") }}
          </div>
          <div>
            {{ $moment(row.item.executed_date, ["h:mm"]).locale("en").format("hh:mm A") }}
          </div>
        </div>
      </template>
    </data-table>
    <form-modal
      ref="estateModalVisitors"
      :formSchema="estatesFormSchema"
      title=""
      v-model="activModel"
      :loading="loading"
      @confirm="onModalConfirmed"
    >
      <template #office_id="item">
        <form-select
          v-model="office_id"
          :reduce="(x) => x.id"
          :getOptionLabel="(x) => x.office_no"
          :id="`office_id_visitor_${item.form.service_id}`"
          :key="`office_id_visitor_${item.form.service_id}`"
          label="رقم المكتب "
          ep="select/offices"
          :utils="{ service_id: item.form.service_id }"
        />
      </template>
    </form-modal>
  </b-card>
</template>

<script>
import DataTable from "@/components/data-table/index";
import FormSelect from "@/components/form-select/index";
import FormInput from "@/components/form-input/index";
import FormModal from "@/components/FormModal.vue";

import {
  BCard,
  BRow,
  BCol,
  BBadge,
  BDropdown,
  BDropdownItem,
  BButton,
  BCollapse,
  VBToggle,
  VBTooltip,
} from "bootstrap-vue";
import { mapActions, mapGetters } from "vuex";
import Ripple from "vue-ripple-directive";
import HeaderTable from "@/components/data-table/components/header-table.vue";
import ActionsTable from "@/components/data-table/components/actions-table.vue";
export default {
  components: {
    VBToggle,
    VBTooltip,
    Ripple,
    BCollapse,
    DataTable,
    BCard,
    BRow,
    BCol,
    FormSelect,
    FormInput,
    BBadge,
    BDropdown,
    BDropdownItem,
    BButton,
    FormModal,
    HeaderTable,
    ActionsTable,
  },
  directives: {
    "b-tooltip": VBTooltip,
    "b-toggle": VBToggle,
    Ripple,
  },
  data() {
    return {
      status: "",
      office_id: "",
      service_id: "",
      is_execute: "",
      beneficiary_name: "",
      activModel: false,
      filter: "",
      fields: [
        { key: "id", label: "رقم البطاقة" },
        { key: "beneficiary_name", label: "اسم المستفيد" },
        { key: "phone_number", label: "رقم الموبايل" },
        { key: "cost", label: "الكلفة" },
        { key: "service.title", label: "الخدمة" },
        { key: "executed_date", label: "التوقيت" },
        { key: "actions", label: "" },
      ],
      optionsState: [
        { value: 1, label: "منفذ" },
        { value: 0, label: "غير منفذ " },
      ],
      allStatusVisitor: [
        { label: 'منجز', value: 'D' },
        { label: 'قيد الانتظار', value: 'P' },
        { label: 'غير منفذ', value: 'R' },
      ],
      estatesFormSchema: {
        beneficiary_name: {
          component: "FormInput",
          key: "beneficiary_name",
          attrs: {
            label: "اسم المستفيد",
            rules: "required",
          },
        },
        cost: {
          component: "FormMask",
          key: "cost",
          attrs: {
            options: "number",
            rules: "required",
            label: "التكلفة",
          },
        },
        phone_number: {
          component: "FormMask",
          key: "phone_number",
          attrs: {
            options: "phone",
            rules: "required|min:10",
            label: "رقم الموبايل",
            dir: "ltr",
          },
        },
        service_id: {
          component: "FormSelect",
          key: "service_id",
          attrs: {
            reduce: (x) => x.id,
            getOptionLabel: (x) => x.title,
            id: "service_id_visitor",
            label: "الخدمة",
            ep: "select/procedures-and-services",
            rules: "required",
          },
        },
        office_id: {
          key: "office_id",
        },
        notes: {
          component: "FormTextarea",
          key: "notes",
          attrs: {
            label: "الملاحظات",
            rules: "required",
          },
        },
      },
    };
  },
  computed: {
    ...mapGetters("manageCenter/manageVisitor", ["loading"]),
  },
  watch: {
    service_id(val) {
      this.$refs.estatesTable.refreshTable();
      console.log(this.service_id);
    },
    is_execute(val) {
      this.$refs.estatesTable.refreshTable();
    },
    status(){
      this.$refs.estatesTable.refreshTable();
    }
  },
  methods: {
    ...mapActions("manageCenter/manageVisitor", [
      "updateVisitor",
      "createVisitor",
      "deleteVisitor",
    ]),

    onModalConfirmed(form) {
      if (form.id) {
        this.updateVisitor({
          id: form.id,
          fd: { ...form, cost: form.cost.replace(/\D/g, ""), office_id: this.office_id },
        }).then(() => {
          this.activModel = false;
          this.$refs.estatesTable.refreshTable();
        });
      } else
        this.createVisitor({
          fd: {
            ...form,
            cost: form.cost.replace(/\D/g, ""),
            is_execute: 0,
            is_by_queue: 0,
            office_id: this.office_id,
          },
        }).then(() => {
          this.activModel = false;
          this.$refs.estatesTable.refreshTable();
        });
    },
    onDelete(item) {
      this.$bvModal
        .msgBoxConfirm("هل أنت متأكد من حذف الإجراء", {
          title: "تأكيد حذف الإجراء",
          size: "sm",
          okVariant: "danger",
          okTitle: "تأكيد",
          cancelTitle: "إلغاء",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value)
            this.deleteVisitor({ id: item.id }).then(() => {
              this.$refs.estatesTable.refreshTable();
            });
        });
    },
    onClickedAdd() {
      this.$refs.estateModalVisitors.init({});
      this.activModel = true;
    },
    onUpdateClicked(item) {
      var toUpdate = {
        id: item.id,
        beneficiary_name: item.beneficiary_name,
        phone_number: item.phone_number,
        cost: item.cost,
        notes: item.notes,
        service_id: item.service_id,
        office_id: item.office_id,
        is_execute: 0,
        is_by_queue: 0,
      };
      this.$refs.estateModalVisitors.init(toUpdate);
      this.activModel = true;
    },
  },
};
</script>
